.architecture {
  display: flex;
  position: relative;
  margin-bottom: 85px;
  .item {
    width: 50%;
    img {
      width: 100%;
      position: relative;
      opacity: 1;
      transition: 1s;
      left: 0;
      bottom: 0;
      &.start {
        left: -80px;
        bottom: 80px;
        opacity: 0;
      }
    }
  }
  .cell {
    max-width: 538px;
    padding-left: 65px;
    padding-top: 25px;
    position: relative;
    opacity: 1;
    transition: 1s;
    left: 0;
    top: 0;
    &.start {
      opacity: 0;
      left: 80px;
      top: 80px;
    }
    h2 {
      margin-bottom: 52px;
    }
  }
  .button {
    margin-top: 42px;
  }
}

@media all and (max-width: 940px) {
  .architecture {
    flex-wrap: wrap;
    .item {
      width: 100%;
    }
  }
}

@media all and (max-width: 700px) {
  .architecture {
    margin-bottom: 40px;
    .cell {
      padding: 25px 20px;
    }
    .button {
      padding: 16px;
      font-size: 14px;
    }
  }
}

@media all and (max-width: 420px) {
  .architecture .button {
    width: 100%;
    text-align: center;
  }
}

